.content-holder {
    height: 50vh;
    width: 50%;
    display: flex;
    margin-left: 250px;
}

@media print {
    .pagebreak { page-break-before: always; }
}

.front{
    height: 375px;
    width: 250px;
    position: relative;
    border-radius: 10px;
}

.top{
    height: 30%;
    width: 100%;
    background-color: #4c5a7d;
    position: relative;
    z-index: 5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    flex: 1;
    display: flex;
    flex-direction: row;
}

.back-university-name{
    height: 20%;
    width: 100%;
    background-color: #4c5a7d;
    position: relative;
    z-index: 5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.bottom{
    height: 70%;
    width: 100%;
    background-color: #f5f2f2;
    position: absolute;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-color: #4c5a7d
}

.top_img{
    height: 100px;
    width: 100px;
    background-color: #e6ebe0;
    border-radius: 10px;
    position: absolute;
    top:60px;
    left: 75px;
}
.bottom p{
    position: relative;
    top: 60px;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 20px;
    text-emphasis: spacing;
}
.bottom .desi{
    font-size:12px;
    color: grey;
    font-weight: normal;
}
.bottom .no{
    font-size: 15px;
    font-weight: normal;
}
.barcode img
{
    height: 65px;
    width: 65px;
    text-align: center;
    margin: 5px;
}
.barcode{
    text-align: center;
    position: relative;
    top: 45px;
}
.expiry-date{
    text-align: center;
    position: relative;
    top: 50px;
}

.staff-type{
    text-align: center;
    position: relative;
    top: 60px;
    background-color: #46455b;
    position: relative;
    z-index: 5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: whitesmoke;
}
.staff-type-back{
    text-align: center;
    position: relative;
    background-color: #46455b;
    z-index: 5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: whitesmoke;
    
    /*height: 84%;*/
    width: 102%;
}


.back
{
    height: 375px;
    width: 250px;
    border-radius: 10px;
    background-color: white;
    position: relative;
}

.university-name{
    background-color: #4c5a7d;
    height: 20%;
    width: 100%;
    position: relative;
    z-index: 5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.qr img{
    height: 80px;
    width: 100%;
    margin: 20px;
    background-color: white;
}
.Details {
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 20px;
}

.fr_details {
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    flex: 1;
    flex-direction: row;
}

.front-top-text{
    color: white;
    font-size: larger;
    font-weight: bold;
    font-size: 20px;
    margin: 15px;
    margin-top: 10px;
    text-align: center;
    line-height: 1.1;
}
.back-text{
    font-size: 13px;
    text-align: center;
}

.details-info{
    text-align: left;
    padding: 5px;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    background-color: #faf5f5;
    height: 84%;
    width: 100%;
    position: absolute;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 15px;
}

.details-info-back{
    text-align: left;
    padding: 5px;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    background-color: #faf5f5;
    height: 80.5%;
    width: 100%;
    position: absolute;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 15px;
}


.logo {
    height: 40px;
    width: 150px;
    padding: 40px;
}

.logo img{
    height: 100%;
    width: 100%;
    color: white ;

}
.padding{
    padding-right: 20px;
}

@media screen and (max-width:400px)
{
    .container{
        height: 130vh;
    }
    .container .front{
        margin-top: 50px;
    }
}
@media screen and (max-width:600px)
{
    .container{
        height: 130vh;
    }
    .container .front{
        margin-top: 50px;
    }

}
